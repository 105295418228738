<template>
  <v-form @submit.prevent="tryToLogin">
    <v-card-text>
      <w-input
        id="login"
        name="login"
        outlined
        placeholder=" "
        prepend-inner-icon="mdi-account"
        :model="schema.login"
        :label="schema.login.$title"
      />

      <w-input
        id="password"
        name="password"
        outlined
        placeholder=" "
        prepend-inner-icon="mdi-lock"
        type="password"
        class="mt-6"
        :label="schema.password.$title"
        :model="schema.password"
      />
    </v-card-text>

    <v-card-actions>
      <span v-if="wrongLoginData" id="LoginError">{{ wrongLoginData }}</span>

      <v-btn
        depressed
        large
        block
        rounded
        type="submit"
        color="primary"
        style="margin: auto;"
        :loading="loading"
      >Zaloguj</v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import { WInput } from '@witkac/vuetify-components';

// @vuese
// Formularz logowania
// @group Forms
export default {
  components: { WInput },
  props: {
    schema: { type: Object, required: true },
    wrongLoginData: { type: Error, required: false },
    loading: { type: Boolean, default: false }
  },

  data() {
    return {
      showPassword: false
    };
  },
  methods: {
    tryToLogin() {
      this.$emit('tryToLogin');
    }
  }
};
</script>
