<template>
  <v-app>
    <v-row>
      <v-col cols="12" md="8" class="d-flex align-center justify-center login-image-column">
        <img src="@/assets/LoginBg.jpg" alt="Szpital" />

        <v-card flat class="login-container">
          <div class="login__title">Logowanie</div>

          <p class="mt-4">System zarządzania informacjami o pacjentach objętych opieką</p>

          <div class="login__form">
            <div v-if="errorMessage">{{ errorMessage }}</div>

            <login-form
              :schema="$schema"
              :wrong-logind-data="errorMessage"
              :loading="loading"
              @tryToLogin="tryToLogin"
            />
          </div>
        </v-card>
      </v-col>

      <v-col cols="12" md="4" class="login-right-column">
        <img src="@/assets/logo-white.png" alt="logo" />
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import { schemaMixin } from '@witkac/vuetify-components';

import LoginForm from '@/components/forms/LoginForm';
import { Role } from '@/enums/role.enum';
import schema from '@/schemas/login.schema.json';

export default {
  mixins: [schemaMixin],
  components: { LoginForm },
  schema,
  props: {
    source: String
  },
  data() {
    return {
      showError: false,
      errorMessage: undefined,
      loading: false,
      model: {
        login: '',
        password: ''
      }
    };
  },
  methods: {
    // @vuese
    // Metoda próbuje zalogować użytkownika po podanych danych
    async tryToLogin() {
      const errors = this.$schema.$validateAll();
      if (errors.length) {
        return;
      }
      this.loading = true;
      const invalidPasswordMessage = 'Niepoprawny login lub hasło';
      this.$store
        .dispatch('auth/login', { userName: this.model.login, password: this.model.password })
        .then(profile => {
          if (profile.role === Role.Admin) {
            return this.$router.replace({ name: 'all-patients' });
          }
          this.$router.replace({ name: 'patients' });
        })
        .catch(error => {
          const message = error.message || error;
          if (message.includes(invalidPasswordMessage) || message.includes('Username or password is incorrect')) {
            this.errorMessage = invalidPasswordMessage;
          } else {
            this.errorMessage = message;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.login-image-column {
  overflow: hidden;
  position: relative;
  padding: 24px;
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    filter: blur(30px);
  }
}

.login-right-column {
  background-color: $color-active;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 180px;
    max-width: 100%;
  }
}

.login-container {
  border-radius: 80px;
  width: 600px;
  padding: 80px 40px;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.login__title {
  font-family: $font-content;
  font-weight: $weight-black;
  color: $color-text;
  font-size: 32px;
}

.login__form {
  margin-top: 40px;
  width: 100%;
}

@media (max-width: #{$breakpoint-sm}) {
  .login-container {
    padding: 24px 16px;
    border-radius: 8px;

    .v-input {
      margin-top: 16px !important;
    }
  }
  .login-right-column {
    img {
      width: 180px;
      max-width: 100%;
    }
  }
}
</style>
